/* eslint-disable no-underscore-dangle */
function isTextBlock(block) {
  return (
    block?._type === 'blockBuilderText' ||
    (block?.copy && block?.copy?._type === 'blockBuilderText')
  );
}

function isReuseableBlock(block) {
  return (
    block._type === 'blockBuilderReusableBlock' &&
    block?.reusableBlockRef?.blocks
  );
}

function extractChildrenText(block) {
  return block?.children?.map(child => child.text).join(' ') || '';
}

function extractBlockBuilderText(block) {
  const textBlocks = block?.copy?.text || block?.text;
  return textBlocks.map(textBlock => extractChildrenText(textBlock)).join(' ');
}

function extractReusableBlockText(blockBuilderReusableBlock) {
  return blockBuilderReusableBlock.reusableBlockRef.blocks
    .map(refBlock => {
      if (refBlock._type === 'blockBuilderAccordion') {
        return refBlock?.items
          ?.map(item => {
            if (isTextBlock(item)) {
              return extractBlockBuilderText(item) + item.headline;
            }
            return item.headline;
          })
          .join(' ');
      }
      return null;
    })
    .filter(e => e)
    .join(' ');
}

function sanityBlockToPlainText(blocks = []) {
  return blocks
    .map(block => {
      if (isReuseableBlock(block)) return extractReusableBlockText(block);
      if (isTextBlock(block)) return extractBlockBuilderText(block);

      // ! place any new text extractions above this line as below is catch all
      if (block._type !== 'block' || !block.children) return '';
      return extractChildrenText(block);
    })
    .filter(e => e)
    .join(' ');
}

module.exports = sanityBlockToPlainText;
