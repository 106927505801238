const isBrowser: boolean = typeof window !== 'undefined';

interface Price {
  amount?: number;
}

interface Variant {
  sku?: string;
  title?: string;
  price?: Price | number;
}

interface Product {
  title?: string;
  productType?: string;
  vendor?: string;
}

interface Item {
  quantity?: number;
  variant: Variant;
  product: Product;
  category?: string;
  isSubscription?: boolean;
}

function isPriceObject(price: Price | number): price is Price {
  return typeof price === 'object' && price !== null;
}

const variantToGaItem = ({
  quantity = 1,
  variant,
  product,
  category,
  isSubscription,
}: Item) => {
  const data: any = {
    item_id: variant?.sku,
    item_name: product?.title,
    item_category: product?.productType,
    item_brand: product?.vendor,
    item_variant: variant?.title?.replace('Default Title', ''),
    price: isPriceObject(variant?.price)
      ? variant.price.amount
      : variant?.price,
    quantity,
    affiliation: 'Gatsby DTC Store',
    isSubscription: !!isSubscription,
  };

  // mutate data item_categoryX if they have category string `/shop/dog/doors` or default to using productType if available
  if (category) {
    category
      ?.split('/')
      .filter(n => n)
      .slice(0, 5)
      .forEach((cat, i) => {
        if (i === 0) {
          data.item_category = cat?.toLowerCase();
        } else {
          data[`item_category${i}`] = cat?.toLowerCase();
        }
      });
  } else if (product?.productType) {
    data.item_category = product?.productType.toLowerCase();
  }

  return data;
};

const pushToDataLayer = (event, { items, ecommerce }) => {
  if (isBrowser) {
    // GA4 add_to_cart https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
    window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window?.dataLayer?.push({
      event,
      ecommerce: {
        ...ecommerce,
        items: items.map(item => variantToGaItem(item)),
      },
    });
  }
};

export default pushToDataLayer;
