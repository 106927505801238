import React from 'react';
import BreadcrumbWithWrapper from '@rsc/ui-components/BreadcrumbWithWrapper';

const algoliaSearchBreadcrumb = (nbHits, searchState) => (
  <BreadcrumbWithWrapper
    elements={[
      { text: 'Home', link: '/' },
      {
        text: searchState.query
          ? `Search: ${nbHits} results for ${searchState.query}`
          : `Search`,
        link: '/search',
      },
    ]}
  />
);

export default algoliaSearchBreadcrumb;
