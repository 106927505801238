export const styles = {
  fontWeight: {
    black: '900',
    extraBold: '800',
    bold: '700',
    semiBold: '600',
    medium: '500',
    regular: '400',
    light: '300',
    extraLight: '200',
    thin: '100',
  },
  fontSize: {
    '1xs': '0.625rem', // 10px
    sm: '0.8125rem', // 13px
    base: '1rem', // 16px
    lg: '1.1875rem', // 19px
    '1xl': '1.5rem', // 24px
    '2xl': '1.75rem', // 28px
    '3xl': '2.25rem', // 36px
    '4xl': '2.625rem', // 42px
    '5xl': '3.755rem', // 60px
    '6xl': '4rem', // 64px
  },
  spacing: {
    'spacing-2xs': '.25rem', // 4px
    'spacing-1xs': '.375rem', // 6px
    'spacing-sm': '.625rem', // 10px
    'spacing-md': '1rem', // 16px
    'spacing-lg': '1.625rem', // 26px
    'spacing-1xl': '2.625rem', // 42px
    'spacing-2xl': '4.25rem', // 68px
    'spacing-3xl': '6.875rem', // 110px
    'spacing-4xl': '11.125rem', // 178px
    'spacing-5xl': '18rem', // 288px
    'spacing-6xl': '29rem', // 464px
  },
};

export default styles;
