import styles from './utils/styles';

const colors = {
  accordion: {
    background: '#e8e8e8',
    backgroundHover: '#d1d1d1',
  },
  accountMenuBackground: styles.colors.gray[50],
  accountMenuItemBorder: styles.colors.gray[100],
  accountMenuItemCurrentBackground: 'white',
  background: '#ffffff',
  badge: styles.colors.blue[500],
  border: styles.colors.blue[100],
  brandPrimary: styles.colors.blue[500],
  brandSecondary: '#ffffff',
  breadcrumbLinkColor: styles.colors.blue[500],
  carouselBulletHover: styles.colors.gray[500],
  comparisonChart: {
    alternateRowShade: styles.colors.gray[50],
    cellBorder: styles.colors.gray[200],
    highlightColumn: {
      primary: {
        backgroundColor: styles.colors.blue[500],
        color: '#FFFFFF',
      },
      secondary: {
        backgroundColor: styles.colors.blue[50],
        color: styles.colors.blue[500],
      },
      tertiary: {
        backgroundColor: styles.colors.blue[900],
        color: '#FFFFFF',
      },
    },
    mobileSubhead: {
      backgroundColor: styles.colors.blue[50],
    },
  },
  contentCard: {
    primary: {
      backgroundColor: styles.colors.blue[500],
      color: '#FFFFFF',
      border: 'none',
    },
    secondary: {
      backgroundColor: styles.colors.blue[50],
      color: styles.colors.blue[500],
      border: styles.colors.blue[100],
    },
    tertiary: {
      backgroundColor: styles.colors.blue[900],
      color: '#FFFFFF',
      border: 'none',
    },
    quaternary: {
      backgroundColor: 'transparent',
      color: styles.colors.blue[500],
      border: styles.colors.blue[100],
    },
  },
  defaultFont: styles.colors.gray[500],
  hero: {
    mobileHeadlineColor: styles.colors.blue[500],
    primary: {
      backgroundColor: styles.colors.blue[500],
      color: '#FFFFFF'
    },
    secondary: {
      backgroundColor: 'transparent',
      color: styles.colors.blue[500]
    },
    tertiary: {
      backgroundColor: styles.colors.blue[500],
      color: styles.colors.blue[50]
    },
    quaternary: {
      backgroundColor: 'transparent',
      color: styles.colors.gray[500]
    }
  },
  iconButtonFill: styles.colors.gray[500],
  iconButtonBackgroundHover: styles.colors.blue[100],
  inputBorder: styles.colors.gray[300],
  inputFontColor: styles.colors.gray[500],
  link: styles.colors.blue[500],
  linkHover: styles.colors.blue[500],
  searchBorder: styles.colors.gray[200],
  searchInput: styles.colors.gray[500],
  tagBackground: styles.colors.blue[100],
  cardBackground: '#ffffff',
  cardBackgroundHover: styles.colors.gray[50],
  cardText: styles.colors.gray[500],
  navSearchAllResultsColor: styles.colors.gray[500],
  navSearchHitsContainerText: styles.colors.gray[800],
  searchFormHitBorderColor: styles.colors.gray[200],
  headline: styles.colors.blue[500],
  typography: {
    brandContrast: '#ffffff',
  },
};

export default colors;
