import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import BreadcrumbArrow from './svgs/breadcrumb-arrow.svg';

const StyledBreadcrumb = styled.div`
  display: inline;
  color: ${props => (props.color ? props.color : '#5d5d5d')};
  a {
    color: ${props => (props.color ? props.color : '#5d5d5d')};
    &:hover {
      text-decoration: underline;
    }
  }
`;

// Takes in array of {text, link} or {text} if last element
function Breadcrumb({ elements, useSVG }) {
  return (
    <nav aria-label="Breadcrumb">
      {elements &&
        elements.map(({ text, link }, index) =>
          elements.length !== index + 1 ? (
            <StyledBreadcrumb key={text}>
              <Link to={`${link}`}>{text}</Link>
              {useSVG ? <BreadcrumbArrow /> : <span>{' > '}</span>}
            </StyledBreadcrumb>
          ) : (
            <StyledBreadcrumb key={text}>
              <span>{text}</span>
            </StyledBreadcrumb>
          )
        )}
    </nav>
  );
}

Breadcrumb.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  useSVG: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  useSVG: false,
};

export default Breadcrumb;
