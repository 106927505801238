let baseUrl =
  process.env.CONTEXT === 'production'
    ? process.env.URL
    : process.env.DEPLOY_PRIME_URL;

if (!baseUrl) {
  if (typeof window !== 'undefined') {
    baseUrl = new URL(window.location.origin);
  }
}

const brands = [
  {
    name: 'Kurgo',
    baseUrl,
    logo: `${baseUrl}/images/kurgo_social.png`,
    seo: {
      title: 'Kurgo: High Quality Outdoor Adventure Dog Gear Products',
      description:
        'Kurgo is a leading supplier of quality dog travel supplies, seat covers, harnesses & accessories for active dogs. Click to learn more about our dog accessories.',
    },
    twitter: '@Kurgo',
  },
  {
    name: 'SportDOG',
    baseUrl,
    logo: `${baseUrl}/images/opengraph-fallback.png`,
    seo: {
      title:
        'Dog Training Collars for Hunting Dogs, Gun Dogs, Sporting Dogs - SportDOG ®',
      description:
        'Hunting dog gear designed by sportsmen. Sporting dog training, tracking, and hunting information to get the most out of your next hunt.',
    },
    twitter: '@SportDOGBrand',
  },
  {
    name: 'InvisibleFence',
    baseUrl,
    logo: `${baseUrl}/images/opengraph-fallback.png`,
    seo: {
      title: 'Invisible Fence',
      description: 'Invisible Fence',
    },
    twitter: '@invisiblefence',
  },
  {
    name: 'PetSafe',
    baseUrl,
    logo: `${baseUrl}/images/opengraph-fallback.png`,
    seo: {
      title: 'PetSafe® | Dog Fences | Feeders | Collars | Cat Litter Boxes',
      description:
        "PetSafe® Brand is the world's leader in containment, training, and lifestyle solutions to give pet owners more great moments with their pets.",
    },
    twitter: '@petsafe',
  },
];

const createOpenGraphTags = (meta, brand) => {
  const activeBrand = brands.find(
    b => b.name.toLowerCase() === brand.toLowerCase()
  );

  const openGraphImageUrl = image => {
    if (!image) return activeBrand.logo;
    if (typeof image === 'string') return image;

    // if sanity image, let's crop it
    let url = `${image?.asset?.url}?w=1200&h=630`;
    if (image?.hotspot) {
      // if we've got a hotspot, use it
      const { x } = image.hotspot;
      const { y } = image.hotspot;
      url += `&fit=crop&crop=focalpoint&fp-x=${x}&fp-y=${y}`;
    }

    return url;
  };

  const {
    title = activeBrand.seo.title,
    description = activeBrand.seo.description,
    url = '/',
    image = activeBrand.logo,
    type = 'website',
  } = meta;

  const metaTags = [
    /*
     * OpenGraph
     */
    {
      property: 'og:image',
      content: openGraphImageUrl(image),
    },
    { property: 'og:image', content: `${activeBrand.logo}` },
    {
      property: 'og:url',
      content: `${activeBrand.baseUrl}${url}`,
    },
    {
      property: 'og:type',
      content: type,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    /*
     * Twitter
     */
    {
      name: 'twitter:image',
      content: openGraphImageUrl(image),
    },
    { name: 'og:image', content: `${activeBrand.logo}` },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:domain',
      content: `${activeBrand.baseUrl}`,
    },
    {
      name: 'twitter:url',
      content: `${activeBrand.baseUrl}${url}`,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:site',
      content: activeBrand.twitter,
    },
  ];

  // Generates Article Structure (tags, published_at, etc.) based on meta.type
  // https://ogp.me/
  if (meta?.type === 'article' && meta?.article) {
    const articleDetails = meta.article;
    Object.keys(articleDetails).forEach(key => {
      if (key !== 'tag') {
        metaTags.push({
          name: `article:${key}`,
          content: articleDetails[key],
        });
      } else {
        articleDetails[key].forEach(tag => {
          metaTags.push({
            name: `article:tag`,
            content: tag,
          });
        });
      }
    });
  }

  return metaTags;
};

export default createOpenGraphTags;
