import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import { CartProvider } from '@rsc/contexts/CartContext';
import CustomerContextProvider from '@rsc/contexts/CustomerContext';
import { DealerSearchProvider } from '@rsc/contexts/DealerSearchContext';
import { LocaleProvider } from '@rsc/contexts/LocaleContext';
import { SiteSettingsProvider } from '@rsc/contexts/SiteSettingsContext';
import { RechargeSubscriptionsProvider } from '@rsc/contexts/RechargeSubscriptionsContext';
import useSiteSettings from '@components/hooks/useSiteSettings';

import theme from './src/components/theme';

function RootWrapper({ element }: { element: React.ReactElement }) {
  return (
    <ThemeProvider theme={theme}>
      <SiteSettingsProvider useSiteSettings={useSiteSettings}>
        <LocaleProvider allowedCurrencies={['USD', 'CAD']}>
          <CustomerContextProvider>
            <RechargeSubscriptionsProvider
              subscriptionsMenuLabel="Location/Tracking Subscriptions"
              cancelSubscriptionMessage={
                <p>
                  All future billing of this subscription will be canceled.
                  <br />
                  You can start a new subscription at any time by selecting
                  Activate Collar in the Invisible Fence app.
                </p>
              }
            >
              <CartProvider disableProductTagsOnCheckout>
                <DealerSearchProvider>{element}</DealerSearchProvider>
              </CartProvider>
            </RechargeSubscriptionsProvider>
          </CustomerContextProvider>
        </LocaleProvider>
      </SiteSettingsProvider>
    </ThemeProvider>
  );
}

export default RootWrapper;
