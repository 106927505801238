import PropTypes from 'prop-types';
import slugify from 'slugify';

export const LinkShape = PropTypes.shape({
  linkType: PropTypes.string,
  href: PropTypes.string,
  internalLink: PropTypes.shape({}),
});

export const getCollectionLink = title => {
  const newSlug = title
    ?.split('/')
    .map(level => slugify(level, { lower: true, strict: true, trim: true }))
    .join('/');

  const pathname = `/shop/${newSlug}`;

  if (pathname.includes('all-products')) {
    return pathname.replace('/all-products', '');
  }
  return pathname;
};

const getLink = (type, href, internalLink) => {
  if (type === 'external') {
    return href;
  }
  if (type === 'internal') {
    switch (internalLink?._type) {
      case 'page':
        if (internalLink?.slug?.current === '/homepage') {
          return `/`;
        }
        return `/${internalLink.slug.current}`;

      case 'product':
        return `/product/${internalLink.store.slug.current}`;

      case 'pressRelease':
        return `/press/${internalLink.slug.current}`;

      case 'blogPost':
        return `/blog/${internalLink.slug.current}`;

      case 'blogCategory':
        return `/blog/${internalLink.slug.current}`;

      case 'collection':
        return getCollectionLink(internalLink?.store?.title);

      default:
        return `/${internalLink?.slug?.current}`;
    }
  }
  return undefined;
};

export default getLink;
