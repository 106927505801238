const getCookieDomain = () => {
  const buildUrl =
    process.env.CONTEXT === 'production' ||
    process.env.CONTEXT === 'branch-deploy'
      ? process.env.URL
      : process.env.DEPLOY_PRIME_URL;

  let url = '';
  if (buildUrl) {
    // parse hostname from url
    url = new URL(buildUrl);
  } else if (typeof window !== 'undefined') {
    url = new URL(window.location.origin);
  } else {
    return '';
  }
  if (
    url.hostname.includes('localhost') ||
    url.hostname.includes('netlify.app')
  ) {
    return url.hostname;
  } // remove all subdomains
  return url.hostname.replace(/^([^.]+)\./, '');
};
export default getCookieDomain;
