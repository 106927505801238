import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const SiteSettingsContext = createContext();

function SiteSettingsProvider({ children, useSiteSettings }) {
  const defaultSiteSettings = useSiteSettings();
  const [currentSiteSettings, setCurrentSiteSettings] =
    useState(defaultSiteSettings);

  const updateSiteSettings = newSiteSettings => {
    setCurrentSiteSettings(newSiteSettings);
  };

  const memoizedValue = useMemo(
    () => ({
      siteSettings: currentSiteSettings,
      updateSiteSettings,
    }),
    [currentSiteSettings]
  );

  return (
    <SiteSettingsContext.Provider value={memoizedValue}>
      {children}
    </SiteSettingsContext.Provider>
  );
}

SiteSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  useSiteSettings: PropTypes.func.isRequired,
};

export { SiteSettingsProvider, SiteSettingsContext };
