/* eslint sort-keys: "error" */
import PropTypes from 'prop-types';
import breakpoints from './breakpoints';

import fallbackLogo from './images/logo.svg';
import ChevronDown from './svgs/chevron-down.svg';

// Shared default theme object
// each site's theme will get this automatically and can be overridden
// in the site's theme object

const DefaultThemeProps = {
  accountMenuBackground: '#f8f8f8',
  accountMenuItemBorder: '#CD6598',
  accountMenuItemCurrentBackground: '#ffffff',
  breakpoints,
  button: {
    borderRadius: '0',
    borderRadiusInner: '0',
    borderStyle: 'solid',
    borderStyleHover: '',
    borderStyleInner: 'none',
    borderStyleInnerHover: '',
    borderWidth: '1px',
    borderWidthInner: '0',
    boxShadow: 'none',
    padding: '0.625rem',
    paddingInner: '0',
    primary: {
      background: '#666666',
      backgroundActive: '#000000',
      backgroundDisabled: '#444444',
      backgroundHover: '#555555',
      borderColor: 'transparent',
      borderColorActive: '',
      borderColorHover: '',
      borderColorInner: 'transparent',
      borderColorInnerActive: '',
      borderColorInnerHover: '',
      textColor: '#ffffff',
      textColorActive: '',
      textColorHover: '',
    },
    secondary: {
      background: '#666666',
      backgroundActive: '#000000',
      backgroundDisabled: '#444444',
      backgroundHover: '#555555',
      borderColor: 'transparent',
      borderColorActive: '',
      borderColorHover: '',
      borderColorInner: 'transparent',
      borderColorInnerActive: '',
      borderColorInnerHover: '',
      textColor: '#ffffff',
      textColorActive: '',
      textColorHover: '',
    },
    tertiary: {
      background: '#666666',
      backgroundActive: '#000000',
      backgroundDisabled: '#444444',
      backgroundHover: '#555555',
      borderColor: 'transparent',
      borderColorActive: '',
      borderColorHover: '',
      borderColorInner: 'transparent',
      borderColorInnerActive: '',
      borderColorInnerHover: '',
      textColor: '#ffffff',
      textColorActive: '',
      textColorHover: '',
    },
    textTransform: 'none',
  },
  colors: {
    accordion: {
      background: '#e8e8e8',
      backgroundHover: '#d1d1d1',
    },
    background: '#ffffff',
    badge: '#777777',
    badgeText: '#ffffff',
    border: '#CD6598',
    brandPrimary: '#CD6598',
    brandSecondary: '#FEDBD4',
    breadcrumbLinkColor: '#00000',
    cardBackground: '#333333',
    cardText: '#ffffff',
    carouselBullet: '#bbbbbb',
    carouselBulletHover: '#666666',
    comparisonChart: {
      alternateRowShade: '#bbbbbb',
      cellBorder: '#bbbbbb',
      highlightColumn: {
        primary: {
          backgroundColor: '#CD6598',
          color: '#FFFFFF',
        },
        secondary: {
          backgroundColor: '#AD4578',
          color: '#CD6598',
        },
        tertiary: {
          backgroundColor: '#FD95C8',
          color: '#FFFFFF',
        },
      },
      mobileSubhead: {
        backgroundColor: '#FEDBD4',
      },
    },
    contentCard: {
      primary: {
        backgroundColor: '#CD6598',
        border: 'none',
        color: '#FFFFFF'
      },
      quaternary: {
        backgroundColor: '#FFFFFF',
        border: '#FFFFFF',
        color: '#FEDBD4'
      },
      secondary: {
        backgroundColor: '#FFFFFF',
        border: '#000000',
        color: '#CD6598'
      },
      tertiary: {
        backgroundColor: '#FEDBD4',
        border: 'none',
        color: '#FFFFFF'
      }
    },
    defaultFont: '#333333',
    hero: {
      mobileHeadlineColor: '#CD6598',
      primary: {
        color: '#CD6598'
      },
      quaternary: {
        color: '#EEEBD4'
      },
      secondary: {
        color: '#FEDBD4'
      },
      tertiary: {
        color: '#FFFBD4'
      }
    },
    iconButtonBackgroundHover: '#666666',
    iconButtonFill: '#222222',
    inputBorder: '#333333',
    inputFontColor: '#333333',
    link: '#0000ee',
    linkHover: '#0000ee',
    navSearchAllResultsColor: '#646464',
    navSearchHitsContainerText: '#2e2e2e',
    onSaleRed: '#C10000',
    searchBorder: '#CD6598',
    searchFormHitBorderColor: '#d4d4d4',
    searchInput: '#646464',
    searchInputBackground: '#ffffff',
    tagBackground: '#333333',
    tagBackgroundHover: '#666666',
    typography: {
      brandContrast: '#333333',
    },
  },
  footer: {
    backgroundColor: '#ffffff',
    fontColor: '#000000',
    socialIconFill: '#666666',
    socialIconFillHover: '#333333',
  },
  headerBackground: '#444444',
  headline: '#444444',
  hero: {
    fontColor: '#ffffff',
    span: {
      textTransform: 'none',
    },
  },
  images: {
    logo: fallbackLogo,
    pagePreviewFallbackImage: fallbackLogo,
  },
  logoHeight: '64px',
  name: 'Default Theme',
  navigation: {
    DownArrow: ChevronDown,
    NavDesktopMenuItemFontSize: '1rem',
    downArrowSize: 22,
    downArrowSpacing: '0.25rem',
    fontWeight: 'normal',
    mainItemTextTransform: 'inherit',
    subSectionHeadingTextTransform: 'inherit',
  },
  notificationBanner: {
    background: '#CD6598',
  },
  productCard: {
    backgroundColor: '#ffffff',
    borderColor: 'transparent',
    borderColorHover: '#eeeeee',
    boxShadow: 'none',
    boxShadowHover: '0 0 30px 0 rgba(153, 153, 153, 0.1)',
    inlineRatingsMarginBottom: '0.9375rem',
    priceColor: 'revert',
    titleColor: 'inherit',
    titleColorHover: '#000000',
    titleFontWeight: 'normal',
    titleTextTransform: 'inherit',
  },
  quizCard: {
    recommendationBackgroundColor: 'transparent',
  },
};

// Shared default theme props
// each shared component will receive this automatically,
// keeping it in line with the DefaultTheme object

const DefaultThemePropTypes = {
  /* eslint-disable sort-keys */
  accountMenuBackground: PropTypes.string,
  accountMenuItemBorder: PropTypes.string,
  accountMenuItemCurrentBackground: PropTypes.string,
  breakpoints: PropTypes.exact({
    sm: PropTypes.string.isRequired,
    md: PropTypes.string.isRequired,
    lg: PropTypes.string.isRequired,
    xl: PropTypes.string.isRequired,
  }),

  /* eslint-enable sort-keys */
  button: {
    borderRadius: PropTypes.string,
    borderRadiusInner: PropTypes.string,
    borderStyle: PropTypes.string,
    borderStyleHover: PropTypes.string,
    borderStyleInner: PropTypes.string,
    borderStyleInnerHover: PropTypes.string,
    borderWidth: PropTypes.string,
    borderWidthInner: PropTypes.string,
    boxShadow: PropTypes.string,
    padding: PropTypes.string,
    paddingInner: PropTypes.string,
    primary: {
      background: PropTypes.string,
      backgroundActive: PropTypes.string,
      backgroundDisabled: PropTypes.string,
      backgroundHover: PropTypes.string,
      borderColor: PropTypes.string,
      borderColorActive: PropTypes.string,
      borderColorHover: PropTypes.string,
      borderColorInner: PropTypes.string,
      borderColorInnerActive: PropTypes.string,
      borderColorInnerHover: PropTypes.string,
      textColor: PropTypes.string,
      textColorActive: PropTypes.string,
      textColorHover: PropTypes.string,
    },
    secondary: {
      background: PropTypes.string,
      backgroundActive: PropTypes.string,
      backgroundDisabled: PropTypes.string,
      backgroundHover: PropTypes.string,
      borderColor: PropTypes.string,
      borderColorActive: PropTypes.string,
      borderColorHover: PropTypes.string,
      borderColorInner: PropTypes.string,
      borderColorInnerActive: PropTypes.string,
      borderColorInnerHover: PropTypes.string,
      textColor: PropTypes.string,
      textColorActive: PropTypes.string,
      textColorHover: PropTypes.string,
    },
    tertiary: {
      background: PropTypes.string,
      backgroundActive: PropTypes.string,
      backgroundDisabled: PropTypes.string,
      backgroundHover: PropTypes.string,
      borderColor: PropTypes.string,
      borderColorActive: PropTypes.string,
      borderColorHover: PropTypes.string,
      borderColorInner: PropTypes.string,
      borderColorInnerActive: PropTypes.string,
      borderColorInnerHover: PropTypes.string,
      textColor: PropTypes.string,
      textColorActive: PropTypes.string,
      textColorHover: PropTypes.string,
    },
    textTransform: PropTypes.string,
  },
  colors: PropTypes.exact({
    background: PropTypes.string,
    badge: PropTypes.string,
    badgeText: PropTypes.string,
    border: PropTypes.string,
    brandPrimary: PropTypes.string,
    brandSecondary: PropTypes.string,
    cardBackground: PropTypes.string,
    cardText: PropTypes.string,
    carouselBullet: PropTypes.string,
    carouselBulletHover: PropTypes.string,
    contentCard: PropTypes.shape({}),
    defaultFont: PropTypes.string,
    headerBackground: PropTypes.string,
    headline: PropTypes.string,
    hero: PropTypes.shape({
      mobileHeadlineColor: PropTypes.string,
      primary: PropTypes.shape({}),
      quaternary: PropTypes.shape({}),
      secondary: PropTypes.shape({}),
      tertiary: PropTypes.shape({})
    }),
    iconButtonBackgroundHover: PropTypes.string,
    iconButtonFill: PropTypes.string,
    inputBorder: PropTypes.string,
    inputFontColor: PropTypes.string,
    link: PropTypes.string,
    linkHover: PropTypes.string,
    onSaleRed: PropTypes.string,
    searchInput: PropTypes.string,
    typography: PropTypes.exact({
      brandContrast: PropTypes.string,
    }),
  }),
  images: PropTypes.exact({
    logo: PropTypes.string,
  }),
  logoHeight: PropTypes.string,
  name: PropTypes.string,
  navigation: PropTypes.exact({
    mainItemTextTransform: PropTypes.string,
    subSectionHeadingTextTransform: PropTypes.string,
  }),
  notificationBanner: PropTypes.exact({
    background: PropTypes.string,
  }),
  productCard: PropTypes.exact({
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    borderColorHover: PropTypes.string,
    boxShadow: PropTypes.string,
    boxShadowHover: PropTypes.string,
    inlineRatingsMarginBottom: PropTypes.string,
    priceColor: PropTypes.string,
    titleColor: PropTypes.string,
    titleColorHover: PropTypes.string,
    titleTextTransform: PropTypes.string,
  }),
  quizCard: PropTypes.exact({
    recommendationBackgroundColor: PropTypes.string,
  }),
};

export { DefaultThemeProps as DefaultTheme, DefaultThemePropTypes };
