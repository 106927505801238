const CART_LINE_ITEMS_QUERY = `
    lines(first:20) {
        edges {
            node {
                id
                quantity
                discountAllocations {
                    ... on CartAutomaticDiscountAllocation {
                        discountedAmount {
                            amount
                            currencyCode
                        }
                        title
                    }
                }
                sellingPlanAllocation {
                    priceAdjustments {
                        price {
                            amount
                            currencyCode
                        }
                    }
                    sellingPlan {
                        id
                    }
                }
                merchandise {
                    __typename
                    ... on ProductVariant {
                        id
                        title
                        sku
                        quantityAvailable
                        compareAtPrice {
                            amount
                            currencyCode
                        }
                        selectedOptions {
                          name
                          value
                        }
                        price {
                            amount
                        }
                        image {
                            id
                            src
                            url
                            altText
                        }
                        product {
                            title
                            handle
                            productType
                            vendor
                            tags
                            sellingPlanGroups(first: 1) {
                              nodes {
                                sellingPlans(first: 1) {
                                  nodes {
                                    id
                                    priceAdjustments {
                                      adjustmentValue {
                                        ... on SellingPlanPercentagePriceAdjustment {
                                          adjustmentPercentage
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                        }
                    }
                }
                attributes {
                    key
                    value
                }
            }
        }
    }
`;

const CART_QUERY = `
    id
    note
    checkoutUrl
    totalQuantity
    createdAt
    updatedAt
    cost {
        subtotalAmount {
            amount
            currencyCode
        }
    }
    buyerIdentity {
        countryCode
        email
        phone
        walletPreferences
    }
    attributes {
        key
        value
    }
`;

export const CREATE_CART_QUERY = `
    mutation cartCreate($input: CartInput) {
        cartCreate(input: $input) {
            cart {
                ${CART_QUERY}
                ${CART_LINE_ITEMS_QUERY}
            }
            userErrors {
                field
                message
            }
        }
    }
`;

export const GET_CART_QUERY = `
    query CartQuery($id: ID!) {
        cart(id: $id) {
            ${CART_QUERY}
            ${CART_LINE_ITEMS_QUERY}
        }
    }
`;

export const ADD_LINE_ITEM_QUERY = `
    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
            cart {
                ${CART_QUERY}
                ${CART_LINE_ITEMS_QUERY}
            }
            userErrors {
                field
                message
            }
        }
    }
`;

export const REMOVE_LINE_ITEM_QUERY = `
    mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
            cart {
                ${CART_QUERY}
                ${CART_LINE_ITEMS_QUERY}
            }
            userErrors {
                field
                message
            }
        }
    }
`;

export const UPDATE_LINE_ITEM_QUERY = `
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
            cart {
                ${CART_QUERY}
                ${CART_LINE_ITEMS_QUERY}
            }
            userErrors {
                field
                message
            }
        }
    }
`;

export const FETCH_PRODUCT_UPDATE_QUERY = `
    query ProductUpdateQuery($id: ID!) {
        product(id: $id) {
            title
            variants(first: 100) {
                nodes {
                    id
                    sku
                    availableForSale
                }
            }
            sellingPlanGroups(first: 1) {
                nodes {
                    sellingPlans(first: 7) {
                        nodes {
                          id
                          name
                        }
                    }
                }
            }
        }
    }
`;

export const UPDATE_BUYER_IDENTITY_QUERY = `
    mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!) {
        cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
            cart {
                ${CART_QUERY}
            }
            userErrors {
                field
                message
            }
        }
    }  
`;

export const UPDATE_CART_ATTRIBUTES_QUERY = `
    mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
        cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
            cart {
                ${CART_QUERY}
                ${CART_LINE_ITEMS_QUERY}
            }
            userErrors {
                field
                message
            }
        }
    }
`;
