import { DefaultTheme } from '@rsc/ui-components/theme';

import styles from './utils/styles';
import themeColors from './themeColors';

import IfbLogo from '../images/ifb-square.svg';
import IfbMobileLogo from '../images/ifb.svg';
import IfbPagePreviewFallbackImage from '../images/page-preview-fallback.jpg';

// use shared template and extend/override the custom theme values

const theme = {
  ...DefaultTheme,
  button: {
    ...DefaultTheme.button,
    primary: {
      ...DefaultTheme.button.primary,
      background: styles.colors.blue[500],
      backgroundHover: styles.colors.blue[700],
      backgroundActive: styles.colors.blue[900],
      backgroundDisabled: styles.colors.blue[600],
      borderColor: styles.colors.blue[500],
    },
    secondary: {
      ...DefaultTheme.button.secondary,
      background: '#ffffff',
      backgroundHover: styles.colors.gray[200],
      backgroundActive: styles.colors.gray[300],
      backgroundDisabled: '#ffffff',
      borderColor: styles.colors.blue[500],
      textColor: styles.colors.blue[500],
    },
    tertiary: {
      ...DefaultTheme.button.tertiary,
      background: styles.colors.blue[500],
      backgroundHover: styles.colors.gray[800],
      backgroundActive: styles.colors.blue[900],
      backgroundDisabled: styles.colors.blue[600],
      borderColor: styles.colors.blue[500],
    },
  },
  colors: {
    ...DefaultTheme.colors,
    ...themeColors,
  },
  footer: {
    backgroundColor: styles.colors.blue[900],
    fontColor: '#ffffff',
    socialIconFill: '#ffffff',
    socialIconFillHover: styles.colors.blue[400],
  },
  headerBackground: '#ffffff',
  hero: {
    fontColor: '#ffffff',
    span: {
      textTransform: 'none',
    },
  },
  images: {
    logo: IfbLogo,
    mobileLogo: IfbMobileLogo,
    pagePreviewFallbackImage: IfbPagePreviewFallbackImage,
  },
  logoHeight: '26px',
  name: 'InvisibleFence Theme',
  notificationBanner: {
    background: styles.colors.blue[500],
  },
  productCard: {
    ...DefaultTheme.productCard,
    borderColorHover: styles.colors.gray[100],
    boxShadowHover: 'none',
    priceColor: styles.colors.blue[500],
    titleColor: styles.colors.blue[500],
    titleColorHover: styles.colors.blue[500],
    titleFontWeight: 'bold',
    titleTextTransform: 'inherit',
  },
  quizCard: {
    recommendationBackgroundColor: styles.colors.blue[50],
  },
  fontWeight: {
    ...DefaultTheme.fontWeight,
  },
};

export default theme;
