/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

const withTwoPassRendering = WrappedComponent =>
  function twoPass({ children, ...rest }) {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
      setIsClient(true);
    }, [setIsClient]);

    return (
      <WrappedComponent {...rest} key={isClient}>
        {children}
      </WrappedComponent>
    );
  };

export default withTwoPassRendering;
