import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import sharedStyles from '@rsc/ui-components/styles';
import Breadcrumb from './Breadcrumb';
import breakpoints from './breakpoints';

const BreadcrumbWrapper = styled.div`
  padding: 0 1.75rem;
  padding-bottom: 0;

  a,
  svg {
    color: ${({ theme }) => theme.colors.breadcrumbLinkColor};
    font-weight: normal;
    font-size: ${sharedStyles.fontSize.base};
    line-height: 2;
  }
  span {
    color: ${({ theme }) => theme.colors.cardText};
    font-weight: normal;
    font-size: ${sharedStyles.fontSize.base};
    line-height: 2;
  }

  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};

  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

function BreadcrumbWithWrapper({ elements, hideOnMobile }) {
  return (
    <BreadcrumbWrapper hideOnMobile={hideOnMobile}>
      <Breadcrumb elements={elements} />
    </BreadcrumbWrapper>
  );
}

BreadcrumbWithWrapper.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  hideOnMobile: PropTypes.bool,
};

BreadcrumbWithWrapper.defaultProps = {
  hideOnMobile: false,
};

export default BreadcrumbWithWrapper;
