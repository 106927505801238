import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

const getHeaderMessage = headerSettings => {
  if (
    headerSettings?.scheduledMessageEnabled &&
    headerSettings?.scheduledMessage
  ) {
    // calculate whether the scheduled message should be displayed
    try {
      if (headerSettings.startDate && headerSettings.endDate) {
        const current = dayjs(Date.now()).tz('America/New_York');
        const start = dayjs(headerSettings.startDate).tz(
          'America/New_York',
          true
        );
        const end = dayjs(headerSettings.endDate).tz('America/New_York', true);

        if (current.isAfter(start) && current.isBefore(end)) {
          return headerSettings.scheduledLink
            ? {
                message: headerSettings?.scheduledMessage,
                link: headerSettings?.scheduledLink,
              }
            : headerSettings.scheduledMessage;
        }
        console.error('Invalid date added for notification banner');
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (headerSettings?.messageLink) {
    return {
      message: headerSettings?.messageText,
      link: headerSettings?.messageLink,
    };
  }

  return { message: headerSettings?.messageText };
};

export default getHeaderMessage;
