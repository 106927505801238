import { useStaticQuery, graphql } from 'gatsby';

const useSiteSettings = () => {
  const { sanitySiteSettings } = useStaticQuery(
    graphql`
      query SanitySiteSettingsQuery {
        sanitySiteSettings(
          _type: { eq: "siteSettings" }
          siteIdentifier: { eq: "ifb" }
        ) {
          phone
          contactPage {
            slug {
              current
            }
          }
          manageSubscriptionMessage {
            text: _rawText(resolveReferences: { maxDepth: 4 })
          }
          manageSubscriptionSupportMessage {
            text: _rawText(resolveReferences: { maxDepth: 4 })
          }
          manageSubscriptionCancelMessage {
            text: _rawText(resolveReferences: { maxDepth: 4 })
          }
          referralProgramContent {
            text: _rawText(resolveReferences: { maxDepth: 4 })
          }
          referralProgramFallbackMessage {
            text: _rawText(resolveReferences: { maxDepth: 4 })
          }
          referralProgramLink {
            internalLink: _rawInternalLink(resolveReferences: { maxDepth: 4 })
            linkType
            href
          }
          ordersPageHeadline
          ordersPageSubHeadline
          subscriptionsPageHeadline
          subscriptionsPageSubHeadline
          videosPageHeadline
          videosPageSubHeadline
          addressesPageHeadline
          addressesPageSubHeadline
          referralProgramPageHeadline
          referralProgramPageSubHeadline
          referralProgramShareSubject
          referralProgramShareMessage
          appointmentsPageHeadline
          appointmentsPageSubHeadline
          settingsPageHeadline
          settingsPageSubHeadline
          manageHubspotFormFallbackMessage {
            text: _rawText(resolveReferences: { maxDepth: 4 })
          }
        }
      }
    `
  );

  const defaultSettings = {
    contactPage: '/support/contact',
    phone: '1 (800) 578-3647',
    manageSubscriptionMessage: { text: null },
    manageSubscriptionSupportMessage: { text: null },
    manageSubscriptionCancelMessage: { text: null },

    referralProgramContent: { text: [] },
    referralProgramFallbackMessage: { text: [] },
    referralProgramLink: {},
    referralProgramShareSubject: 'Invisible Fence Referral Program',
    referralProgramShareMessage: '',

    ordersPageHeadline: 'Your Orders',
    ordersPageSubHeadline: 'View your order history and manage your account.',
    subscriptionsPageHeadline: 'Subscriptions',
    subscriptionsPageSubHeadline:
      'Manage your subscriptions, update payment methods, and view subscription details here.',
    videosPageHeadline: 'Training Videos',
    videosPageSubHeadline:
      'Exclusive training videos to help you train your pet with Invisible Fence technology.',
    addressesPageHeadline: 'Your Addresses',
    addressesPageSubHeadline: 'Manage your delivery addresses here.',
    referralProgramPageHeadline: 'Referral Program',
    referralProgramPageSubHeadline: '',
    appointmentsPageHeadline: 'Appointments',
    appointmentsPageSubHeadline: '',
    settingsPageHeadline: 'Your Account',
    settingsPageSubHeadline: 'Manage your account details and password here.',

    manageHubspotFormFallbackMessage: { text: null },
  };

  const settings = {
    ...defaultSettings,
    ...sanitySiteSettings,
    contactPage: sanitySiteSettings?.contactPage?.slug?.current?.startsWith('/')
      ? sanitySiteSettings.contactPage.slug.current
      : `/${
          sanitySiteSettings?.contactPage?.slug?.current ||
          defaultSettings.contactPage
        }`,
    manageSubscriptionMessage: sanitySiteSettings?.manageSubscriptionMessage
      ?.text
      ? sanitySiteSettings.manageSubscriptionMessage
      : defaultSettings.manageSubscriptionMessage,
    manageSubscriptionSupportMessage: sanitySiteSettings
      ?.manageSubscriptionSupportMessage?.text
      ? sanitySiteSettings.manageSubscriptionSupportMessage
      : defaultSettings.manageSubscriptionSupportMessage,
    manageSubscriptionCancelMessage: sanitySiteSettings
      ?.manageSubscriptionCancelMessage?.text
      ? sanitySiteSettings.manageSubscriptionCancelMessage
      : defaultSettings.manageSubscriptionCancelMessage,
    manageHubspotFormFallbackMessage: sanitySiteSettings
      ?.manageHubspotFormFallbackMessage?.text
      ? sanitySiteSettings.manageHubspotFormFallbackMessage
      : defaultSettings.manageHubspotFormFallbackMessage,
  };

  // Ensure all headlines and sub-headlines have default values if missing
  const headlineFields = [
    'ordersPageHeadline',
    'ordersPageSubHeadline',
    'subscriptionsPageHeadline',
    'subscriptionsPageSubHeadline',
    'videosPageHeadline',
    'videosPageSubHeadline',
    'addressesPageHeadline',
    'addressesPageSubHeadline',
    'referralProgramPageHeadline',
    'referralProgramPageSubHeadline',
    'appointmentsPageHeadline',
    'appointmentsPageSubHeadline',
    'settingsPageHeadline',
    'settingsPageSubHeadline',
  ];

  headlineFields.forEach(field => {
    settings[field] = settings[field] || defaultSettings[field];
  });

  return settings;
};

export default useSiteSettings;
