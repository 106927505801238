import Cookies from 'js-cookie';
import { navigate } from 'gatsby';

import RootWrapper from './RootWrapper';

export const onRouteUpdate = ({ location }) => {
  const countryConfirmed = Cookies.get('country_confirmed');
  const currency = Cookies.get('currency');

  if (
    // client side routing to /en-ca if country is confirmed and currency is CAD
    // and the user hits an non /en-ca route
    countryConfirmed === 'true' &&
    currency === 'CAD' &&
    !location.pathname.includes('en-ca')
  ) {
    // append en-ca to pathname and navigate there
    navigate(`/en-ca${location.pathname}`);
  } else if (
    // client side routing to / if country is confirmed and currency is USD
    // and the user hits an /en-ca route
    countryConfirmed === 'true' &&
    currency === 'USD' &&
    location.pathname.includes('en-ca')
  ) {
    // remove en-ca from pathname and navigate there
    navigate(location.pathname.replace('/en-ca', ''));
  }
};

export const wrapRootElement = RootWrapper;
