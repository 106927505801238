const _ = require('lodash');

const getSortedCollections = node => {
  const sortedCollections = [];

  if (node && node.products) {
    const products = node.products.filter(Boolean);

    sortedCollections.push({
      collection: {
        ...node,
        sortOrder: null,
        products,
      },
    });

    sortedCollections.push({
      collection: {
        ...node,
        sortOrder: 'atoz',
        products: _.sortBy(products, [
          product => product?.shopifyProduct?.title,
        ]),
      },
    });

    sortedCollections.push({
      collection: {
        ...node,
        sortOrder: 'ztoa',
        products: _.sortBy(products, [
          product => product?.shopifyProduct?.title,
        ]).reverse(),
      },
    });

    sortedCollections.push({
      collection: {
        ...node,
        sortOrder: 'priceAsc',
        products: _.sortBy(products, [
          product =>
            parseFloat(
              product?.shopifyProduct?.priceRangeV2.minVariantPrice.amount
            ),
        ]),
      },
    });

    sortedCollections.push({
      collection: {
        ...node,
        sortOrder: 'priceDesc',
        products: _.sortBy(products, [
          product =>
            parseFloat(
              product?.shopifyProduct?.priceRangeV2.minVariantPrice.amount
            ),
        ]).reverse(),
      },
    });

    sortedCollections.push({
      collection: {
        ...node,
        sortOrder: 'newest',
        products: _.sortBy(products, [
          product => parseFloat(product?.shopifyProduct?.publishedAt),
        ]).reverse(),
      },
    });
  }

  return sortedCollections;
};

module.exports = getSortedCollections;
